import { translate } from 'vue-gettext';

const { pgettext: $pgettext } = translate;

export default function (tour) {
  const offset = 24;

  const attachToTop = {
    attachTo: {
      on: 'top',
    },
    tetherOptions: {
      offset: `${offset}px 0`,
    },
  };

  const attachToRight = {
    attachTo: {
      on: 'right',
    },
    tetherOptions: {
      offset: `0 -${offset}px`,
    },
  };

  const attachToBottom = {
    attachTo: {
      on: 'bottom',
    },
    tetherOptions: {
      offset: `-${offset}px 0`,
    },
  };

  const attachToLeft = {
    attachTo: {
      on: 'left',
    },
    tetherOptions: {
      offset: `0 ${offset}px`,
    },
  };

  const defaults = {
    modalOverlayOpeningPadding: 8,
    cancelIcon: {
      enabled: true,
    },
    buttons: [
      {
        action: () => {
          tour.back();
        },
        secondary: true,
        text: $pgettext('Tour navi', 'Previous'),
      },
      {
        action: () => {
          tour.next();
        },
        text: $pgettext('Tour navi', 'Next'),
      },
    ],
  };

  return {
    defaults,
    attachToTop,
    attachToRight,
    attachToBottom,
    attachToLeft,
  };
}
