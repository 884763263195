import { translate } from 'vue-gettext';
import getOptions from './options';

const { pgettext: $pgettext } = translate;

export default function (tour) {
  const {
    defaults,
    attachToRight,
    attachToBottom,
  } = getOptions(tour);

  return {
    teamsRow: {
      ...defaults,
      ...attachToBottom,
      order: 0,
      title: $pgettext('Tour title (project todos by teams 1)', 'Teams'),
      text: `
        ${$pgettext('Tour content (project todos by teams 1)', 'Teams in this project.')}
      `,
    },

    teamTodosCol: {
      ...defaults,
      ...attachToRight,
      order: 1,
      title: $pgettext('Tour title (project todos by teams 2)', "Team's tasks"),
      text: `
        ${$pgettext('Tour content (project todos by teams 2)', 'This column displays all tasks in this takt that this team is responsible for.')}
      `,
    },

    'todo-container': {
      ...defaults,
      ...attachToRight,
      order: 2,
      title: $pgettext('Tour title (project todos by teams 3)', 'Task'),
      text: `
        ${$pgettext('Tour content (project todos by teams 3)', 'A single task with a state, appointed worker and space.')}
      `,
    },

    selectedTacts: {
      ...defaults,
      ...attachToRight,
      order: 3,
      title: $pgettext('Tour title (project todos by teams 4)', 'Takt'),
      text: `
      ${$pgettext('Tour content (project todos by teams 4)', 'A period of time related tasks should be completed in.')}
      `,
    },

    tactRangeSliderRef: {
      ...defaults,
      ...attachToBottom,
      order: 4,
      title: $pgettext('Tour title (project todos by teams 5)', 'Choose takt'),
      text: `
        ${$pgettext('Tour content (project todos by teams 5) [1/3]', 'You can limit the view to display a time frame of one or multiple takts.')}
        <br><br>
        <b>${$pgettext('Tour content (project todos by teams 5) [2/3]', 'TIP')}</b>
        <br>
        ${$pgettext('Tour content (project todos by teams 5) [3/3]', 'Choosing a specific takt can for example be utilized while planning where you need to reference past and upcoming tasks.')}
      `,
    },

    showDueTodosRef: {
      ...defaults,
      ...attachToBottom,
      order: 5,
      title: $pgettext('Tour title (project todos by teams 6)', 'Show late'),
      text: `
        ${$pgettext('Tour content (project todos by teams 6) [1/3]', 'Displays takts with incomplete tasks.')}
        <br><br>
        <b>${$pgettext('Tour content (project todos by teams 6) [2/3]', 'TIP')}</b>
        <br>
        ${$pgettext('Tour content (project todos by teams 6) [3/3]', 'This can be used to examine the status of tasks that are late.')}
      `,
    },

    selectedTeamsRef: {
      ...defaults,
      ...attachToBottom,
      order: 6,
      title: $pgettext('Tour title (project todos by teams 7)', 'Filter teams'),
      text: `
        ${$pgettext('Tour content (project todos by teams 7) [1/3]', 'You can limit the view to only display data from selected teams.')}
        <br><br>
        <b>${$pgettext('Tour content (project todos by teams 7) [2/3]', 'TIP')}</b>
        <br>
        ${$pgettext('Tour content (project todos by teams 7) [3/3]', 'This can for example be used in subcontractor meetings where only the tasks of those subcontractors are of interest.')}
      `,
    },
  };
}
