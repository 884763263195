import { translate } from 'vue-gettext';
import getOptions from './options';

const { pgettext: $pgettext } = translate;

export default function (tour) {
  const {
    defaults,
    attachToBottom,
  } = getOptions(tour);

  return {
    teamTrendTitle: {
      ...defaults,
      ...attachToBottom,
      order: 0,
      title: $pgettext('Tour title (team trends)', 'Team trends'),
      text: `
        ${$pgettext(
          'Tour content (team trends) [1/8]',
          'Team trends help management assess how subcontractors are dealing with their planned workloads.',
        )}
        <br><br>
        ${$pgettext(
          'Tour content (team trends) [2/8]',
          "A subcontractor's work progresses as planned when the 'Complete' graph follows the 'Planned' graph as closely as possible.",
        )}
        <br><br>
        <b>${$pgettext(
          'Tour content (team trends) [3/8]',
          'Late, not started / Late, started',
        )}</b>
        <br>
        ${$pgettext(
          'Tour content (team trends) [4/8]',
          'If tasks are late ("Late, not started") it is useful to keep an eye on the "Late, started" graph. With it you can interpret whether or not the subcontractor can catch up with the schedule.',
        )}
        <br><br>
        <b>${$pgettext(
          'Tour content (team trends) [5/8]',
          'Late, interrupted',
        )}</b>
        <br>
        ${$pgettext(
          'Tour content (team trends) [6/8]',
          'The "Late, interrupted" graph shows how many tasks have been interrupted. It is possible that the project will not stay on schedule if the amount of interruptions keeps growing constantly.',
        )}
        <br><br>
        <b>${$pgettext(
          'Tour content (team trends) [7/8]',
          'Ahead',
        )}</b>
        <br>
        ${$pgettext(
          'Tour content (team trends) [8/8]',
          '"Ahead, started" and "Ahead, complete" express work ahead of schedule. Typically it is not considered good practice to work ahead, but it can be used to form a buffer in the schedule and resources of the project.',
        )}
      `,
    },
  };
}
