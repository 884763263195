import { translate } from 'vue-gettext';
import getOptions from './options';

const { pgettext: $pgettext } = translate;

export default function (tour) {
  const {
    defaults,
    attachToBottom,
  } = getOptions(tour);

  return {
    nameRef: {
      ...defaults,
      ...attachToBottom,
      order: 0,
      title: $pgettext('Tour title (work package editor)', 'Package name'),
      text: `
        ${$pgettext('Tour content (work package editor) [1/11]', 'A free-to-choose name for the work package.')}
        <br><br>
        <b>${$pgettext('Tour content (work package editor) [2/11]', 'Examples')}</b>
        <br>
        ${$pgettext('Tour content (work package editor) [3/11]', '- Work package 1')}
        <br>
        ${$pgettext('Tour content (work package editor) [4/11]', '- Work package 2')}
        <br>
        ${$pgettext('Tour content (work package editor) [5/11]', '- Work package 3')}
        <br>
        - ...
        <br>
        <b>${$pgettext('Tour content (work package editor) [6/11]', 'or')}</b>
        <br>
        ${$pgettext('Tour content (work package editor) [7/11]', '- Car 1')}
        <br>
        ${$pgettext('Tour content (work package editor) [8/11]', '- Car 2')}
        <br>
        ${$pgettext('Tour content (work package editor) [9/11]', '- Car 3')}
        <br>
        - ...
        <br><br>
        <b>${$pgettext('Tour content (work package editor) [10/11]', 'TIP')}</b>
        <br>
        ${$pgettext('Tour content (work package editor) [11/11]', 'Use a clear and consistent naming convention when creating work packages.')}
      `,
    },
  };
}
