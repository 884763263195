import { translate } from 'vue-gettext';
import getOptions from './options';

const { pgettext: $pgettext } = translate;

export default function (tour) {
  const {
    defaults,
    attachToLeft,
    attachToRight,
    attachToBottom,
  } = getOptions(tour);

  return {
    pid: {
      ...defaults,
      ...attachToLeft,
      order: 0,
      title: $pgettext('Tour title (project details 1)', 'Project name'),
      text: `
        ${$pgettext('Tour content (project details 1) [1/2]', 'A descriptive name for the project. It is good practice to also include a work number in the name.')}
        <br><br>
        <b>${$pgettext('Tour content (project details 1) [2/2]', 'Example')}<b>
        <br>
        Scandic Mikonkatu facelift - 13650
      `,
    },

    address: {
      ...defaults,
      ...attachToLeft,
      order: 1,
      title: $pgettext('Tour title (project details 2)', 'Address'),
      text: `
        ${$pgettext('Tour content (project details 2)', 'Address of the site')}
      `,
    },

    tactDuration: {
      ...defaults,
      ...attachToLeft,
      order: 2,
      title: $pgettext('Tour title (project details 3)', 'Takt duration'),
      text: `
        ${$pgettext('Tour content (project details 3) [1/2]', "The project's planned takt duration in days.")}
        ${$pgettext('Tour content (project details 3) [2/2]', 'Time interval at which the production moves forward.')}
      `,
    },

    startsAt: {
      ...defaults,
      ...attachToRight,
      order: 3,
      title: $pgettext('Tour title (project details 4)', 'Starting date'),
      text: `
        ${$pgettext('Tour content (project details 4)', 'The date on which production starts.')}
      `,
    },

    excludedDates: {
      ...defaults,
      ...attachToLeft,
      order: 4,
      title: $pgettext('Tour title (project details 5)', 'Non-working days'),
      text: `
        ${$pgettext('Tour content (project details 5)', 'Days on which there is no work done on the site. For example weekends and public holidays.')}
      `,
    },

    saveBtn: {
      ...defaults,
      ...attachToLeft,
      order: 5,
      title: $pgettext('Tour title (project details 6)', 'Save'),
      text: `
        ${$pgettext('Tour content (project details 6) [1/3]', "Saves the project's details.")}
        <br><br>
        ${$pgettext('Tour content (project details 6) [2/3]', 'Changes made in other tabs (PASSAGES and SPACES) are saved automatically.')}
        <br><br>
        ${$pgettext('Tour content (project details 6) [3/3]', 'Changes will be immediately available to all users.')}
      `,
    },

    additionalActions: {
      ...defaults,
      ...attachToRight,
      order: 6,
      title: $pgettext('Tour title (project details 7)', 'More actions'),
      text: `
        ${$pgettext('Tour content (project details 7) [1/3]', 'Other actions related to the project.')}
        <br><br>
        <b>${$pgettext('Tour content (project details 7) [2/3]', 'Delete project')}</b>
        <br>
        ${$pgettext('Tour content (project details 7) [3/3]', 'A destructive process which deletes the project and all associated info and tasks.')}
      `,
    },

    'project-tab-1': {
      ...defaults,
      ...attachToBottom,
      order: 7,
      title: $pgettext('Tour title (project details 8)', 'Passages'),
      text: `
        ${$pgettext('Tour content (project details 8) [1/2]', 'After saving the project, you can continue to the next step from here.')}
        <br><br>
        ${$pgettext('Tour content (project details 8) [2/2]', 'All project details can also be modified afterwards.')}
      `,
    },
  };
}
