import { translate } from 'vue-gettext';
import getOptions from './options';

const { pgettext: $pgettext } = translate;

export default function (tour) {
  const {
    defaults,
    attachToBottom,
  } = getOptions(tour);

  return {
    teamNameRef: {
      ...defaults,
      ...attachToBottom,
      order: 0,
      title: $pgettext('Tour title (team editor)', 'Team name'),
      text: `
        ${$pgettext('Tour content (team editor) [1/4]', 'A free-to-choose name for the team.')}
        <br><br>
        <b>${$pgettext('Tour content (team editor) [2/4]', 'TIP')}</b>
        <br>
        ${$pgettext('Tour content (team editor) [3/4]', 'Give teams clear, consistent and concise names.')}
        <br><br>
        ${$pgettext('Tour content (team editor) [4/4]', 'One way of naming teams is to refer to the related contracts; teams would then be named after their respective subcontractor companies.')}
      `,
    },

    // isManagerRef: {
    //   ...defaults,
    //   ...attachToBottom,
    //   order: 1,
    //   title: 'Työnjohto',
    //   text: `
    //     Voit merkata työryhmän työnjohdoksi.
    //     <br><br>
    //     <b>HUOM</b>
    //     <br>
    //     Mestamaster ei hyödynnä työryhmän tyyppiä toistaiseksi vielä mitenkään. Jatkossa
    //     työnjohdoksi merkatuille työryhmille voidaan yksilöidä joitakin näkymiä kuten
    //     metriikoita.
    //   `,
    // },
  };
}
