import { translate } from 'vue-gettext';
import getOptions from './options';

const { pgettext: $pgettext } = translate;

export default function (tour) {
  const {
    defaults,
    attachToRight,
    attachToBottom,
  } = getOptions(tour);

  return {
    'project-tab-1': {
      ...defaults,
      ...attachToBottom,
      order: 0,
      title: $pgettext('Tour title (project passages 1)', 'Passages'),
      text: `
        ${$pgettext(
          'Tour content (project passages 1) [1/6]',
          'A passage forms the recipe for all the work steps to execute in a space.',
        )}
        <br><br>
        ${$pgettext(
          'Tour content (project passages 1) [2/6]',
          'It is possible that spaces are built with different recipes. Thus it is possible to create multiple recipes (passages).',
        )}
        <br><br>
        ${$pgettext(
          'Tour content (project passages 1) [3/6]',
          'In the next step the passage you created will be connected to a space (spatial information).',
        )}
        <br><br>
        <b>${$pgettext(
          'Tour content (project passages 1) [4/6]',
          'TIP',
        )}</b>
        <br>
        ${$pgettext(
          'Tour content (project passages 1) [5/6]',
          'The contents of a passage can and should be edited when a project progresses.',
        )}
        ${$pgettext(
          'Tour content (project passages 1) [6/6]',
          'This practice supports continuous improvement which tries to continuously enhance the production.',
        )}
      `,
    },

    passagesRef: {
      ...defaults,
      ...attachToRight,
      order: 1,
      title: $pgettext('Tour title (project passages 2)', 'Created passages'),
      text: `
        ${$pgettext(
          'Tour content (project passages 2) [1/8]',
          'Here are the passages you have created.',
        )}
        <br><br>
        <b>${$pgettext(
          'Tour content (project passages 2) [2/8]',
          'Choosing a passage',
        )}</b>
        <br>
        ${$pgettext(
          'Tour content (project passages 2) [3/8]',
          'Mouse left click',
        )}
        <br><br>
        <b>${$pgettext(
          'Tour content (project passages 2) [4/8]',
          'Editing content',
        )}</b>
        <br>
        <br>
        ${$pgettext(
          'Tour content (project passages 2) [5/8]',
          'Mouse right click',
        )}
        <br><br>
        <b>${$pgettext(
          'Tour content (project passages 2) [6/8]',
          'Ordering',
        )}</b>
        <br>
        ${$pgettext(
          'Tour content (project passages 2) [7/8]',
          'You can reorder passages with the arrow buttons (< and >).',
        )}
        ${$pgettext(
          'Tour content (project passages 2) [8/8]',
          'Reordering passages helps maintaining an understandable structure but it does not affect the respective order of work packages or tasks.',
        )}
      `,
    },

    newPassageBtn: {
      ...defaults,
      ...attachToRight,
      order: 2,
      title: $pgettext('Tour title (project passages 3)', 'Create a passage'),
      text: `
        ${$pgettext('Tour content (project passages 3)', 'Here you can create a new passage.')}
      `,
    },

    teamsRef: {
      ...defaults,
      ...attachToRight,
      order: 3,
      title: $pgettext('Tour title (project passages 4)', 'Teams'),
      text: `
        ${$pgettext(
          'Tour content (project passages 4) [1/8]',
          'A team is a group of one or more workers. Each team is responsible for its own tasks.',
        )}
        <br><br>
        ${$pgettext(
          'Tour content (project passages 4) [2/8]',
          "For clarity teams can be named after their respective subcontractor contracts. Then a team's name would be set as the subcontractor company's name.",
        )}
        <br><br>
        <b>${$pgettext(
          'Tour content (project passages 4) [3/8]',
          'TIP',
        )}</b>
        <br>
        ${$pgettext(
          'Tour content (project passages 4) [4/8]',
          'If it looks like a team gets a lot of tasks it can be divided into smaller and more distinct teams. Having smaller teams assists project management.',
        )}
        <br><br>
        <b>${$pgettext(
          'Tour content (project passages 4) [5/8]',
          'Example',
        )}</b>
        <br>
        ${$pgettext(
          'Tour content (project passages 4) [6/8]',
          'Construction Masters Ltd can be split into the following smaller teams:',
        )}
        <br>
        ${$pgettext(
          'Tour content (project passages 4) [7/8]',
          '- Construction Masters Ltd partition walls',
        )}
        <br>
        ${$pgettext(
          'Tour content (project passages 4) [8/8]',
          '- Construction Masters Ltd equipment and finish',
        )}
      `,
    },

    newTeamBtn: {
      ...defaults,
      ...attachToRight,
      order: 4,
      title: $pgettext('Tour title (project passages 5)', 'Create a team'),
      text: `
        ${$pgettext(
          'Tour content (project passages 5) [1/3]',
          'Here you can create a new team.',
        )}
        <br><br>
        <b>${$pgettext(
          'Tour content (project passages 5) [2/3]',
          'PLEASE NOTE',
        )}</b>
        <br>
        ${$pgettext(
        'Tour content (project passages 5) [3/3]',
        'A team is available for all passages.',
        )}
      `,
    },

    workPackagesRef: {
      ...defaults,
      ...attachToBottom,
      order: 5,
      title: $pgettext('Tour title (project passages 6)', 'Work packages'),
      text: `
        ${$pgettext(
          'Tour content (project passages 6) [1/13]',
          'A work package defines the tasks to complete during a single takt.',
        )}
        <br><br>
        ${$pgettext(
          'Tour content (project passages 6) [2/13]',
          'It is recommended to use a consistent naming convention for work packages. Typical conventions are',
        )}
        <br>
        ${$pgettext(
          'Tour content (project passages 6) [3/13]',
          '- "Work package 1, Work package 2, Work package 3, ..."',
        )}
        <br>
        ${$pgettext(
          'Tour content (project passages 6) [4/13]',
          '- "Car 1, Car 2, Car 3, ..."',
        )}
        <br>
        ${$pgettext(
          'Tour content (project passages 6) [5/13]',
          '- "1, 2, 3, ..."',
        )}
        <br><br>
        <b>${$pgettext(
          'Tour content (project passages 6) [6/13]',
          'PLEASE NOTE 1',
        )}</b>
        <br>
        ${$pgettext(
          'Tour content (project passages 6) [7/13]',
          'A good rule of thumb is that the amount of work a team has in a work package is always less than the takt length.',
        )}
        ${$pgettext(
          'Tour content (project passages 6) [8/13]',
          'Defining too much work can lead to tasks missing their deadlines.',
        )}
        <br><br>
        <b>${$pgettext(
          'Tour content (project passages 6) [9/13]',
          'PLEASE NOTE 2',
        )}</b>
        <br>
        ${$pgettext(
          'Tour content (project passages 6) [10/13]',
          'It is recommended to minimize dependencies between the tasks of different teams.',
        )}
        ${$pgettext(
          'Tour content (project passages 6) [11/13]',
          'Having too many dependencies can impair project management. 2-3 dependencies per package is the recommended maximum.',
        )}
        <br><br>
        <b>${$pgettext(
          'Tour content (project passages 6) [12/13]',
          'PLEASE NOTE 3',
        )}</b>
        <br>
        ${$pgettext(
          'Tour content (project passages 6) [13/13]',
          'There are many variables to consider when planning work packages. Our consultants are always there to assist you.',
        )}
      `,
    },

    newWorkPackageBtn: {
      ...defaults,
      ...attachToRight,
      order: 6,
      title: $pgettext('Tour title (project passages 7)', 'Create a work package'),
      text: `
        ${$pgettext(
          'Tour content (project passages 7)',
          'Here you can create a new work package.',
        )}
      `,
    },

    task: {
      ...defaults,
      ...attachToRight,
      order: 7,
      title: $pgettext('Tour title (project passages 8)', 'Tasks'),
      text: `
        ${$pgettext(
          'Tour content (project passages 8) [1/7]',
          'A task represents a single work item to be completed by a team.',
        )}
        <br><br>
        ${$pgettext(
          'Tour content (project passages 8) [2/6]',
          'Tasks need to be set into the right order. Ordering creates dependencies between tasks.',
        )}
        <br><br>
        <b>${$pgettext(
          'Tour content (project passages 8) [3/6]',
          'Parallel tasks',
        )}</b>
        <br>
        ${$pgettext(
          'Tour content (project passages 8) [4/6]',
          'Can be executed simultaneously.',
        )}
        <br><br>
        <b>${$pgettext(
          'Tour content (project passages 8) [5/6]',
          'Sequential tasks',
        )}</b>
        <br>
        ${$pgettext(
          'Tour content (project passages 8) [6/6]',
          'Are executed in sequence (i.e. the previous task has be completed before the following task can be started).',
        )}
      `,
    },

    newTaskBtn: {
      ...defaults,
      ...attachToRight,
      order: 8,
      title: $pgettext('Tour title (project passages 9)', 'Create a task'),
      text: `
        ${$pgettext(
          'Tour content (project passages 9)',
          'Here you can create a new task.',
        )}
      `,
    },

    'project-tab-2': {
      ...defaults,
      ...attachToBottom,
      order: 9,
      title: $pgettext('Tour title (project passages 10)', 'Spaces'),
      text: `
        ${$pgettext(
          'Tour content (project passages 10)',
          'You can continue to the next step from here after creating passages, teams, work packages and tasks.',
        )}
      `,
    },
  };
}
