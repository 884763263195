/* eslint no-console: "off" */

/**
 * Dynamically includes all tourTemplates.
 *
 * @type {{}}
 */
const components = {};
const context = require.context('@/mixins/tourTemplates/', true, /\.js$/);
context.keys().forEach((fileName) => {
  const component = `${fileName.replace(/(\.\/|\.js)/g, '')}`;
  components[component] = context(fileName).default;
});

export const tour = { // eslint-disable-line
  methods: {
    /**
     * Reads refs from this.$refs and $additionalRefs. $additionalRefs is an object
     * of additionalRefs. Can be for example component's child's refs or such.
     *
     * Returns a list of steps generated from refs.
     *
     * @param __tour__
     * @param template
     * @param $additionalRefs
     * @returns {*[]}
     */
    getTourStepsForRefs(__tour__, template, $additionalRefs) {
      const $refs = {
        ...this.$refs,
        ...$additionalRefs,
      };

      const stepsArray = Object.keys($refs).reduce((acc, refKey) => {
        const steps = components[template](__tour__);
        const stepOptions = steps[refKey];

        if (!stepOptions) {
          if (process.env.NODE_ENV === 'development') {
            console.warn(`Missing a tutorial step for ${refKey}`);
          }

          return acc;
        }

        if (!Number.isInteger(stepOptions.order) && process.env.NODE_ENV === 'development') {
          console.warn(`Missing order property in ${refKey}`);
        }

        const ref = $refs[refKey];
        const htmlEl = Array.isArray(ref) ? ref[0] : ref;

        stepOptions.attachTo.element = htmlEl.$el ? htmlEl.$el : htmlEl;

        acc.push(stepOptions);

        return acc;
      }, []);

      return stepsArray;
    },

    /**
     * $additionalSelectors is an array of selectors. A selector
     * can be either class (prefixed with .) or id (prefixed with #).
     *
     * Returns a list of steps generated from selectors.
     *
     * @param __tour__
     * @param template
     * @param $additionalSelectors
     * @returns {*}
     */
    getTourStepsForSelectors(__tour__, template, $additionalSelectors) {
      const stepsArray = $additionalSelectors.reduce((acc, selector) => {
        const id = selector.split('#')[1];
        const klass = selector.split('.')[1];

        const steps = components[template](__tour__);
        let stepOptions;

        if (id) {
          stepOptions = steps[id];
          stepOptions.attachTo.element = document.getElementById(id);
        } else if (klass) {
          const elements = document.getElementsByClassName(klass);

          stepOptions = steps[klass];
          stepOptions.attachTo.element = elements[0]; // eslint-disable-line
        } else {
          if (process.env.NODE_ENV === 'development') {
            console.warn('Selector should be prefixed with . (class) or # (id)');
          }
          return acc;
        }

        acc.push(stepOptions);

        return acc;
      }, []);

      return stepsArray;
    },

    /**
     * Returns an ordered list of tour steps.
     *
     * @param __tour__
     * @param template
     * @param $additionalRefs
     * @param $additionalSelectors
     * @returns {this}
     */
    getTourSteps(__tour__, template, $additionalRefs, $additionalSelectors) {
      const refSteps = this.getTourStepsForRefs(__tour__, template, $additionalRefs);
      const selectorSteps = this.getTourStepsForSelectors(__tour__, template, $additionalSelectors);

      return [
        ...refSteps,
        ...selectorSteps,
      ].sort((a, b) => (a.order > b.order) ? 1 : -1); // eslint-disable-line
    },

    /**
     * Instantiates a new tour.
     *
     * @param template
     * @param $additionalRefs
     * @param $additionalSelectors
     * @returns {any}
     */
    getTour(template, $additionalRefs, $additionalSelectors) {
      const __tour__ = this.$shepherd({
        useModalOverlay: true,
        defaultStepOptions: {
          classes: 'v-application',
        },
      });

      const steps = this.getTourSteps(__tour__, template, $additionalRefs, $additionalSelectors);

      __tour__.addSteps(steps);
      return __tour__;
    },

    /**
     * Starts a new tour.
     *
     * @param template
     * @param $additionalRefs
     * @param $additionalSelectors
     */
    startTour(template, $additionalRefs = {}, $additionalSelectors = []) {
      const __tour__ = this.getTour(template, $additionalRefs, $additionalSelectors);
      __tour__.start();
    },
  },
};
