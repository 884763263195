import { translate } from 'vue-gettext';
import getOptions from './options';

const { pgettext: $pgettext } = translate;

export default function (tour) {
  const {
    defaults,
    attachToBottom,
  } = getOptions(tour);

  return {
    taskNameRef: {
      ...defaults,
      ...attachToBottom,
      order: 0,
      title: $pgettext('Tour title (task editor 1)', 'Task name'),
      text: `
        ${$pgettext('Tour content (task editor 1) [1/8]', 'A free-to-choose name for the task.')}
        <br><br>
        <b>${$pgettext('Tour content (task editor 1) [2/8]', 'Examples')}</b>
        <br>
        ${$pgettext('Tour content (task editor 1) [3/8]', '- Piping')}
        <br>
        ${$pgettext('Tour content (task editor 1) [4/8]', '- Tiling')}
        <br>
        ${$pgettext('Tour content (task editor 1) [5/8]', '- Hole marking')}
        <br>
        ${$pgettext('Tour content (task editor 1) [6/8]', '- Ventilation ducting')}
        <br><br>
        <b>${$pgettext('Tour content (task editor 1) [7/8]', 'TIP')}</b>
        <br>
        ${$pgettext('Tour content (task editor 1) [8/8]', 'Give tasks clear, consistent and concise names.')}
      `,
    },

    estimatedDurationRef: {
      ...defaults,
      ...attachToBottom,
      order: 1,
      title: $pgettext('Tour title (task editor 2)', 'Estimated execution time'),
      text: `
        ${$pgettext('Tour content (task editor 2) [1/3]', 'Estimated execution time for the task.')}
        <br><br>
        <b>${$pgettext('Tour content (task editor 2) [2/3]', 'TIP')}</b>
        <br>
        ${$pgettext('Tour content (task editor 2) [3/3]', 'Estimate the execution time together with an associated worker; reassess during the project as needed. Doing so helps project management and planning of future projects.')}
      `,
    },

    waitingDurationRef: {
      ...defaults,
      ...attachToBottom,
      order: 2,
      title: $pgettext('Tour title (task editor 3)', 'Estimated wait time'),
      text: `
        ${$pgettext('Tour content (task editor 3) [1/5]', 'Estimated wait time (when complete) before the following task can be started.')}
        <br><br>
        ${$pgettext('Tour content (task editor 3) [2/5]', 'For example tiling work can require a drying time before any following work can begin.')}
        <br><br>
        ${$pgettext('Tour content (task editor 3) [3/5]', "Wait time starts running when the task's state is set to complete.")}
        <br><br>
        <b>${$pgettext('Tour content (task editor 3) [4/5]', 'NOTE')}</b>
        <br>
        ${$pgettext('Tour content (task editor 3) [5/5]', 'Wait time is not yet utilized in Mestamaster. It is however still useful to mark down so that when the feature becomes available, it can immediately be used.')}
      `,
    },

    requiresSupervisionRef: {
      ...defaults,
      ...attachToBottom,
      order: 3,
      title: $pgettext('Tour title (task editor 4)', 'Pace-setting task'),
      text: `
        ${$pgettext('Tour content (task editor 4) [1/6]', 'A pace-setting task can only be started when every preceding task in that space has been completed.')}
        <br><br>
        ${$pgettext('Tour content (task editor 4) [2/6]', 'Tasks following a pace-setting task can only be started when the pace-setting task has been completed.')}
        <br><br>
        <b>${$pgettext('Tour content (task editor 4) [3/6]', 'TIP')}</b>
        <br>
        ${$pgettext('Tour content (task editor 4) [4/6]', 'A pace-setting task can for example be utilized in building inspections.')}
        <br><br>
        ${$pgettext('Tour content (task editor 4) [5/6]', 'Pace-setting tasks can be specified for foremen or external inspectors (for example water insulation inspection).')}
        <br><br>
        ${$pgettext('Tour content (task editor 4) [6/6]', 'The task can only be started when preceding tasks are complete (i.e. work is ready for inspection). Naturally, following tasks can only be started after an approved inspection.')}
      `,
    },
  };
}
