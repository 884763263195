import { translate } from 'vue-gettext';
import getOptions from './options';

const { pgettext: $pgettext } = translate;

export default function (tour) {
  const {
    defaults,
    attachToBottom,
  } = getOptions(tour);

  return {
    passageNameRef: {
      ...defaults,
      ...attachToBottom,
      order: 0,
      title: $pgettext('Tour title (passage editor)', 'Passage name'),
      text: `
        ${$pgettext('Tour content (passage editor) [1/4]', 'A free-to-choose name for the passage')}
        <br><br>
        <b>${$pgettext('Tour content (passage editor) [2/4]', 'Example')}</b>
        <br>
        ${$pgettext('Tour content (passage editor) [3/4]', '- Room passage')}
        <br>
        ${$pgettext('Tour content (passage editor) [4/4]', '- Bathroom passage')}
      `,
    },
  };
}
