import { translate } from 'vue-gettext';
import getOptions from './options';

const { pgettext: $pgettext } = translate;

const getTodoContentColor = (color, state, description) => { // eslint-disable-line
  return `
    <b
      class="m-state-${state} py-1 px-2"
    >
      ${color}
    </b>
    <br>
    <div class="mt-2">${description}</div>
  `;
};

export default function (tour) {
  const {
    defaults,
    attachToBottom,
    attachToLeft,
  } = getOptions(tour);

  return {
    todoRef: {
      ...defaults,
      ...attachToBottom,
      order: 0,
      title: $pgettext('Tour title (todos feed by own team 1)', 'Task'),
      text: `
        ${$pgettext('Tour content (todos feed by own team 1) [1/11]', 'A single task, job or work item.')}
      `,
    },

    todoContentRef: {
      ...defaults,
      ...attachToLeft,
      order: 1,
      title: $pgettext('Tour title (todos feed by own team 2)', 'Task state'),
      text: `
        ${getTodoContentColor(
          $pgettext('Tour content (todos feed by own team 2) [1/10]', 'Not started'),
          'enabled',
          $pgettext('Tour content (todos feed by own team 2) [2/10]', 'Work on the task has not yet started.'),
        )}
        <br>
        ${getTodoContentColor(
          $pgettext('Tour content (todos feed by own team 2) [3/10]', 'Started'),
          'started',
          $pgettext('Tour content (todos feed by own team 2) [4/10]', 'The task is being worked on.'),
        )}
        <br>
        ${getTodoContentColor(
          $pgettext('Tour content (todos feed by own team 2) [5/10]', 'Complete'),
          'completed',
          $pgettext('Tour content (todos feed by own team 2) [6/10]', 'The task has been completed.'),
        )}
        <br>
        ${getTodoContentColor(
          $pgettext('Tour content (todos feed by own team 2) [7/10]', 'Interrupted'),
          'interrupted',
          $pgettext('Tour content (todos feed by own team 2) [8/10]', 'Work on the task has been interrupted.'),
        )}
        <br>
        ${getTodoContentColor(
          $pgettext('Tour content (todos feed by own team 2) [9/10]', 'Cannot begin'),
          'waiting',
          $pgettext('Tour content (todos feed by own team 2) [10/10]', 'Work on the task cannot start until a prior mandatory task has been completed.'),
        )}
      `,
    },

    addressRef: {
      ...defaults,
      ...attachToBottom,
      order: 2,
      title: $pgettext('Tour title (todos feed by own team 3)', 'Space'),
      text: `
        ${$pgettext('Tour content (todos feed by own team 3)', 'A room, area or space where the task is done.')}
      `,
    },

    assigneeRef: {
      ...defaults,
      ...attachToBottom,
      order: 3,
      title: $pgettext('Tour title (todos feed by own team 4)', 'Worker'),
      text: `
        ${$pgettext('Tour content (todos feed by own team 4)', 'Name of the worker appointed for this task.')}
      `,
    },

    todoName: {
      ...defaults,
      ...attachToBottom,
      order: 4,
      title: $pgettext('Tour title (todos feed by own team 5)', 'Task to do'),
      text: `
        ${$pgettext('Tour content (todos feed by own team 5)', 'Name of the task to be done in this space.')}
      `,
    },

    nextStateRef: {
      ...defaults,
      ...attachToBottom,
      order: 6,
      title: $pgettext('Tour title (todos feed by own team 6)', 'Next state'),
      text: `
        ${$pgettext('Tour content (todos feed by own team 6) [1/2]', 'Here you can start or complete work on the task.')}
        <br><br>
        ${$pgettext('Tour content (todos feed by own team 6) [2/2]', 'Continuing work on an interrupted task is also possible through here.')}
      `,
    },

    previousStateBtnRef: {
      ...defaults,
      ...attachToBottom,
      order: 7,
      title: $pgettext('Tour title (todos feed by own team 7)', 'Previous state'),
      text: `
        ${$pgettext('Tour content (todos feed by own team 7) [1/3]', 'Here you can revert the task to a previous state if you made a mistake or just want to continue working on it.')}
        <br><br>
        ${$pgettext('Tour content (todos feed by own team 7) [2/3]', 'Complete -> Started')}
        <br>
        ${$pgettext('Tour content (todos feed by own team 7) [3/3]', 'Started -> Not started')}
      `,
    },

    interruptBtnRef: {
      ...defaults,
      ...attachToBottom,
      order: 8,
      title: $pgettext('Tour title (todos feed by own team 8)', 'Interrupt task'),
      text: `
        ${$pgettext(
          'Tour content (todos feed by own team 8) [1/4]',
          'If you cannot complete the task, report it as interrupted. In the report you can specify a reason for the interruption and write a comment about the problem.',
        )}
        <br><br>
        ${$pgettext(
          'Tour content (todos feed by own team 8) [2/4]',
          'Management will be informed about the interruption and the problem can be handled immediately.',
        )}
        <br><br>
        <b>${$pgettext(
          'Tour content (todos feed by own team 8) [3/4]',
          'PLEASE NOTE',
        )}</b>
        <br>
        ${$pgettext(
          'Tour content (todos feed by own team 8) [4/4]',
          'Reports help you and others stay up to date on the project.',
        )}
      `,
    },

    filterBtn: {
      ...defaults,
      ...attachToBottom,
      order: 9,
      title: $pgettext('Tour title (todos feed by own team 9)', 'Filters'),
      text: `
        ${$pgettext('Tour content (todos feed by own team 9) [1/3]', 'Edit or filter the task list.')}
        <br><br>
        <b>${$pgettext('Tour content (todos feed by own team 9) [2/3]', 'HIDE COMPLETED')}</b>
        <br>
        ${$pgettext('Tour content (todos feed by own team 9) [3/3]', 'Hides completed tasks from the list.')}
      `,
    },

    tactSelection: {
      ...defaults,
      ...attachToBottom,
      order: 10,
      title: $pgettext('Tour title (todos feed by own team 10)', 'Choose takt'),
      text: `
        ${$pgettext('Tour content (todos feed by own team 10)', 'Browse tasks from previous and upcoming takts.')}
      `,
    },

    workLoadRef: {
      ...defaults,
      ...attachToBottom,
      order: 11,
      title: $pgettext('Tour title (todos feed by own team 11)', 'Estimated takt workload'),
      text: `
        ${$pgettext(
          'Tour content (todos feed by own team 11) [1/4]',
          'For example as a subcontractor you can estimate the amount of workforce required for the coming takts.',
        )}
        <br><br>
        <b>${$pgettext(
          'Tour content (todos feed by own team 11) [2/4]',
          'TIP',
        )}</b>
        <br>
        ${$pgettext(
          'Tour content (todos feed by own team 11) [3/4]',
          "A takt's workload is calculated based on its tasks' estimated durations.",
        )}
        <br>
        ${$pgettext(
          'Tour content (todos feed by own team 11) [4/4]',
          "If the estimated workload does not match reality, please talk with your foreman. Together you can readjust the tasks' estimated durations to be more realistic.",
        )}
      `,
    },
  };
}
