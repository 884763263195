import { translate } from 'vue-gettext';
import getOptions from './options';

const { pgettext: $pgettext } = translate;

export default function (tour) {
  const {
    defaults,
    attachToRight,
    attachToBottom,
  } = getOptions(tour);

  return {
    'project-tab-2': {
      ...defaults,
      ...attachToBottom,
      order: 0,
      title: $pgettext('Tour title (project spaces 1)', 'Spaces'),
      text: `
        ${$pgettext('Tour content (project spaces 1) [1/11]', 'A project should be split into spaces with as similarly sized workloads as possible. These spaces are then fed into this table.')}
        <br><br>
        <b>${$pgettext('Tour content (project spaces 1) [2/11]', 'Example')}</b>
        <br>
        ${$pgettext('Tour content (project spaces 1) [3/11]', 'Apartment 1')}
        <br>
        ${$pgettext('Tour content (project spaces 1) [4/11]', 'Apartment 2')}
        <br>
        ${$pgettext('Tour content (project spaces 1) [5/11]', 'Apartment 3')}
        <br><br>
        <b>${$pgettext('Tour content (project spaces 1) [6/11]', 'or')}</b>
        <br><br>
        ${$pgettext('Tour content (project spaces 1) [7/11]', 'Floor 2 Side A')}
        <br>
        ${$pgettext('Tour content (project spaces 1) [8/11]', 'Floor 2 Side B')}
        <br>
        ${$pgettext('Tour content (project spaces 1) [9/11]', 'Floor 2 Side C')}
        <br><br>
        <b>${$pgettext('Tour content (project spaces 1) [10/11]', 'PLEASE NOTE')}</b>
        <br>
        ${$pgettext('Tour content (project spaces 1) [11/11]', 'A space is sometimes referred to as "takt area" or "block".')}
      `,
    },

    spaceRowRef: {
      ...defaults,
      ...attachToBottom,
      order: 0,
      title: $pgettext('Tour title (project spaces 2)', 'Space'),
      text: `
        ${$pgettext('Tour content (project spaces 2) [1/5]', 'A space is an area, environment or room where tasks are executed.')}
        <br><br>
        ${$pgettext('Tour content (project spaces 2) [2/5]', 'A space can for example be an apartment, parts of an apartment (bathroom or living room) or a group of apartments (e.g. half a floor).')}
        <br><br>
        <b>${$pgettext('Tour content (project spaces 2) [3/5]', 'TIP')}</b>
        <br>
        ${$pgettext('Tour content (project spaces 2) [4/5]', 'Granularity (small spaces) typically enhances conditions for continuous improvement.')}
        ${$pgettext('Tour content (project spaces 2) [5/5]', "Smaller spaces enable more detailed planning which improves the project's probability to succeed.")}
      `,
    },

    passageToSpaceCell: {
      ...defaults,
      ...attachToRight,
      order: 1,
      title: $pgettext('Tour title (project spaces 3)', 'Select passage'),
      text: `
        ${$pgettext('Tour content (project spaces 3) [1/2]', 'Here you can attach a space to the passage you created in the previous step.')}
        <br><br>
        ${$pgettext('Tour content (project spaces 3) [2/2]', 'When a passage has been selected, its tasks will be executed in that space.')}
      `,
    },

    spaceStartsOnCell: {
      ...defaults,
      ...attachToRight,
      order: 2,
      title: $pgettext('Tour title (project spaces 4)', 'Set starting takt'),
      text: `
        ${$pgettext('Tour content (project spaces 4) [1/8]', 'Work in this space starts on this takt.')}
        <br><br>
        ${$pgettext('Tour content (project spaces 4) [2/8]', "Starting takts define a project's schedule. A starting takt is always an integer (1, 2, 3, 4, ...) with a minimum value of 1.")}
        <br><br>
        <b>${$pgettext('Tour content (project spaces 4) [3/8]', 'TIP')}</b>
        <br>
        ${$pgettext('Tour content (project spaces 4) [4/8]', "Usually it is best to only start a moderate amount of spaces during a project's first takts.")}
        ${$pgettext('Tour content (project spaces 4) [5/8]', "For example you could start one space per takt in the beginning. Then the spaces' starting takts would be (1, 2, 3, 4, ...).")}
        <br><br>
        ${$pgettext('Tour content (project spaces 4) [6/8]', 'Once the workers have gotten used to the pace, you could for example start three spaces per takt (e.g. ..., 12, 12, 12, 13, 13, 13, 14, 14, 14).')}
        <br><br>
        ${$pgettext('Tour content (project spaces 4) [7/8]', 'Starting takts can also be updated as the project advances.')}
        <br><br>
        ${$pgettext('Tour content (project spaces 4) [8/8]', 'Our consultants are always there to assist you plan the schedule.')}
      `,
    },

    spaceCell: {
      ...defaults,
      ...attachToRight,
      order: 3,
      title: $pgettext('Tour title (project spaces 5)', 'Edit space'),
      text: `
        ${$pgettext('Tour content (project spaces 5)', "Here you can edit the space's details.")}
      `,
    },

    tactSectionRef: {
      ...defaults,
      ...attachToRight,
      order: 4,
      title: $pgettext('Tour title (project spaces 6)', 'Schedule'),
      text: `
        ${$pgettext('Tour content (project spaces 6) [1/9]', "After feeding in the required information, the project's schedule will be drawn into the table.")}
        <br><br>
        ${$pgettext('Tour content (project spaces 6) [2/9]', 'A single cell in the table represents a calendar day.')}
        <br><br>
        <b>${$pgettext('Tour content (project spaces 6) [3/9]', 'EMPTY CELL')}</b>
        <br>
        ${$pgettext('Tour content (project spaces 6) [4/9]', 'No work will be done in this space.')}
        <br><br>
        <b>${$pgettext('Tour content (project spaces 6) [5/9]', 'DARK GREEN LINE')}</b>
        <br>
        ${$pgettext('Tour content (project spaces 6) [6/9]', 'Space will be worked in.')}
        <br><br>
        <b>${$pgettext('Tour content (project spaces 6) [7/9]', 'LIGHT GREEN LINE')}</b>
        <br>
        ${$pgettext('Tour content (project spaces 6) [8/9]', 'Space has been opened but no work will be done on the site (e.g. holiday).')}
        <br><br>
        <b>Great power comes with great responsibility</b>
        <br>
        ${$pgettext('Tour content (project spaces 6) [9/9]', 'Changes made to the schedule will come into effect immediately. Always take care when making changes and work together with whom the changes apply to.')}
      `,
    },

    tactSectionHeaderRef: {
      ...defaults,
      ...attachToRight,
      order: 5,
      title: $pgettext('Tour title (project spaces 7)', 'Dates and takts'),
      text: `
        ${$pgettext('Tour content (project spaces 7)', 'Dates and takts are automatically generated (T1, T2, T3, ...) based on the information fed into the project.')}
      `,
    },

    newSpaceBtnRef: {
      ...defaults,
      ...attachToRight,
      order: 6,
      title: $pgettext('Tour title (project spaces 8)', 'Create a space'),
      text: `
        ${$pgettext('Tour content (project spaces 8) [1/3]', 'Here you can create a new space.')}
        <br><br>
        <b>${$pgettext('Tour content (project spaces 8) [2/3]', 'TIP')}</b>
        <br>
        ${$pgettext('Tour content (project spaces 8) [3/3]', 'Use keyboard shortcut N to speed up the adding of new spaces.')}
      `,
    },
  };
}
