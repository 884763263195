import { translate } from 'vue-gettext';
import getOptions from './options';

const { pgettext: $pgettext } = translate;

export default function (tour) {
  const {
    defaults,
    attachToBottom,
  } = getOptions(tour);

  return {
    spaceAddressRef: {
      ...defaults,
      ...attachToBottom,
      order: 0,
      title: $pgettext('Tour title (space editor)', 'Space name'),
      text: `
        ${$pgettext('Tour content (space editor) [1/11]', 'A free-to-choose name for the space.')}
        <br><br>
        <b>${$pgettext('Tour content (space editor) [2/11]', 'Examples')}</b>
        <br>
        ${$pgettext('Tour content (space editor) [3/11]', '- Block 2 - room')}
        <br>
        ${$pgettext('Tour content (space editor) [4/11]', '- Block 2 - bathroom')}
        <br>
        ${$pgettext('Tour content (space editor) [5/11]', '- Block 3 - room')}
        <br>
        ${$pgettext('Tour content (space editor) [6/11]', '- Block 3 - bathroom')}
        <br>
        ...
        <br>
        <b>${$pgettext('Tour content (space editor) [7/11]', 'or')}</b>
        <br>
        ${$pgettext('Tour content (space editor) [8/11]', '- Apartment 1')}
        <br>
        ${$pgettext('Tour content (space editor) [9/11]', '- Apartment 2')}
        <br>
        ${$pgettext('Tour content (space editor) [10/11]', '- Apartment 3')}
        <br>
        ${$pgettext('Tour content (space editor) [11/11]', '- Apartment 4')}
        <br>
        ...
        <br><br>
        <b>${$pgettext('Tour content (space editor) [12/11]', 'TIP')}</b>
        <br>
        ${$pgettext('Tour content (space editor) [13/11]', 'Use a clear and consistent naming convention when creating spaces.')}
        <br><br>
        ${$pgettext('Tour content (space editor) [14/11]', 'For example with high-rise apartments, spaces can be named after both the apartment number and the building letter.')}
      `,
    },
  };
}
